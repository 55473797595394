export const routesList = [
	{
		path: '/',
		name: 'main',
		component: () => import('../views/Main.vue'),
	},
	// 기존 로그인
	// {
	// 	path: '/login',
	// 	name: 'login',
	// 	component: () => import('../views/Login.vue'),
	// },
	// sso 로그인
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/SsoLogin.vue'),
	},
	// 로그아웃
	// {
	// 	path: '/sso/logout',
	// 	name: 'SsoLogout',
	// 	component: () => import('../views/SsoLogout.vue'),
	// },
	{
		path: '/sso/logout/v2',
		name: 'SsoLogout',
		component: () => import('../views/SsoLogout.vue'),
	},
	// sso 로그인 확인
	{
		path: '/login/success',
		name: 'LoginSuccess',
		component: () => import('../views/LoginSuccess.vue'),
	},
	{
		path: '/login/find-id',
		name: 'Find ID',
		component: () => import('../views/login/FindId.vue'),
	},
	{
		path: '/login/join',
		name: 'Join',
		component: () => import('../views/login/Join.vue'),
	},
	{
		path: '/login/find-password',
		name: 'Find Password',
		component: () => import('../views/login/FindPassword.vue'),
	},
	{
		path: '/login/sns/:userIdx',
		name: 'snsExtraInfo',
		component: () => import('../views/login/snsExtraInfo.vue'),
	},
	{
		path: '/changePassword',
		name: 'changePassword',
		component: () => import('../views/login/ChangePassword.vue'),
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('../views/SearchPage.vue'),
	},
	{
		path: '/content/:id',
		name: 'contentDetail',
		component: () => import('../views/ContentDetail.vue'),
	},
	{
		path: '/creator/:id',
		name: 'Creator',
		component: () => import('../views/Creator.vue'),
	},
	{
		path: '/my-profile',
		name: 'MyProfilePage',
		component: () => import('../views/MyProfilePage.vue'),
	},
	{
		path: '/my-page',
		name: 'MyPage',
		component: () => import('../views/MyPage.vue'),
	},
	{
		path: '/my-page/edit',
		name: 'MyPageEdit',
		component: () => import('../views/MyPageEdit.vue'),
	},
	{
		path: '/my-contents',
		name: 'MyContents',
		component: () => import('../views/MyContents.vue'),
	},
	{
		path: '/community',
		name: 'Community',
		component: () => import('../views/Community.vue'),
	},
	{
		path: '/auth',
		name: 'auth',
		component: () => import('../views/Auth.vue'),
	},
	{
		path: '/d/:id',
		name: 'Contents Short Direct',
		component: () => import('../views/ContentsDirect.vue'),
	},
	{
		path: '/direct/:id',
		name: 'Contents Direct',
		component: () => import('../views/ContentsDirect.vue'),
	},
	{
		path: '/service/:boardTypeCode',
		name: 'policyDetail',
		component: () => import('../views/ServicePage.vue'),
	},
	{
		path: '/IndependenceHall',
		name: 'IndependenceHall',
		component: () => import('../views/Redirect.vue'),
	},
	{
		path: '/IndependenceHall2',
		name: 'IndependenceHall2',
		component: () => import('../views/Redirect.vue'),
	},
	{
		path: '/IndependenceHall3',
		name: 'IndependenceHall3',
		component: () => import('../views/Redirect.vue'),
	},
	{
		path: '/heycomehere',
		name: 'HeyComeHere',
		component: () => import('../views/2410/Introduction.vue'),
	},
	{
		path: '/admin/auth',
		name: 'AdminToPlayAuth',
		component: () => import('../views/AdminToPlayAuth.vue'),
	},
	{
		path: '/2410/reservation',
		name: 'Reservation',
		component: () => import('../views/2410/Reservation.vue'),
	},
	{
		path: '/2410/reservation/:result',
		name: 'ReservationComplete',
		component: () => import('../views/2410/ReservationComplete.vue'),
	},
	{
		path: '/2410/reservationCheck',
		name: 'ReservationCheck',
		component: () => import('../views/2410/ReservationCheck.vue'),
	},
	{
		path: '/2410/reservationCheckResult',
		name: 'ReservationCheckResult',
		component: () => import('../views/2410/ReservationCheckResult.vue'),
	},
	{
		path: '/2410/apocVip/:type',
		name: 'ApocVip',
		component: () => import('../views/2410/ApocVip.vue'),
	},
	{
		path: '/2410/apocVipComplete/:result',
		name: 'ApocVipComplete',
		component: () => import('../views/2410/ApocVipComplete.vue'),
	},
];
